export const getRandomPathSegment = (path) => {
	const pathString = String(path);  // Coerce path to a string
	  const pathSegments = pathString.split("::");
	  if (pathSegments.length > 2) {
		const middleSegments = pathSegments.slice(1, pathSegments.length - 1);
		const randomIndex = Math.floor(Math.random() * middleSegments.length);
		return middleSegments[randomIndex];
	  }
	  // in case there are no middle segments, return the whole path
	  return path;
	}