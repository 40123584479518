import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import './App.css';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { getRandomPathSegment } from './utils.js';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';

const Bubble = ({ name, setSelectedItem, excludedSuggestions, reverse, depth = 0, setDepth, hardModeMovies, gamesPlayed, edges, actorLink }) => {
  const [childBubble, setChildBubble] = useState(null);
  const [connections, setConnections] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');  
  const [modalIsOpen, setModalIsOpen] = useState(false);
 
const openModal = (event) => {
  event.preventDefault();
  setModalIsOpen(true);
};
  
   const closeModal = () => {
    setModalIsOpen(false);
  };
   useEffect(() => { 
	   setChildBubble(null);
	   setInputValue('');
  }, [gamesPlayed]);
  
    useEffect(() => {
    if(edges) {
      const filteredConnections = edges
        .filter(item => item.filterBy === name)
        .map(item => item.connection)
        .sort(); 
      setConnections(filteredConnections);
    }
    excludedSuggestions.current.set(depth, name);
  }, [name, depth, excludedSuggestions, edges]);
  
  const handleSelect = (event, { suggestionValue }) => {  
    setSelectedItem(suggestionValue, depth);
    setDepth(depth + 1);
    setChildBubble(
      <Bubble
        key={`${suggestionValue}-${gamesPlayed}`}
        name={suggestionValue}
        setSelectedItem={setSelectedItem}
        excludedSuggestions={excludedSuggestions}
        reverse={reverse}
        depth={depth + 1}
        setDepth={setDepth}
		edges={edges}
		hardModeMovies={hardModeMovies} 
		actorLink = {actorLink}
      />
    );
    setInputValue(suggestionValue);
    excludedSuggestions.current.set(depth + 1, suggestionValue);
  };

  const handleInputChange = (event, { newValue }) => {  
    setInputValue(newValue);
  };

  const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  
  const newSuggestions = inputLength < 3 ? [] : connections.filter(connection =>
    ![...excludedSuggestions.current.values(), ...hardModeMovies].includes(connection) && connection.toLowerCase().includes(inputValue)
  );

  setSuggestions(newSuggestions);
};

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => (
    <div className="autosuggest-suggestion">
      {suggestion}
    </div>
  );

  const placeholderText = depth % 2 === 0 ? 'Select a movie...' : 'Select an actor...';

  const inputProps = {
    placeholder: placeholderText,
    value: inputValue,
    onChange: handleInputChange
  };

  return (
    <div className={reverse ? "bubble-container-up" : "bubble-container-down"}>
      <div>
        {reverse ? (
          <>
            <Autosuggest
              className="autosuggest-input"
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) => getSuggestions(value)}
              onSuggestionsClearRequested={() => setSuggestions([])}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              onSuggestionSelected={handleSelect}
            />

            {depth === 0 && (
					  <>
						<span className="actor-name">{name}</span>
						<a href="#" onClick={(event) => openModal(event)}>
						  <span role="img" aria-label="camera">📷</span>
						</a>
					  </>
					)}
          </>
        ) : (
          <>
            {depth === 0 && (
					  <>
						<span className="actor-name">{name}</span>
						<a href="#" onClick={(event) => openModal(event)}>
						  <span role="img" aria-label="camera">📷</span>
						</a>
					  </>
					)}
            <Autosuggest
              className="autosuggest-input"
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) => getSuggestions(value)}
              onSuggestionsClearRequested={() => setSuggestions([])}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              onSuggestionSelected={handleSelect}
            />
          </>
        )}
		{modalIsOpen && (
		  <div className="modal">
			<Modal
			  isOpen={modalIsOpen}
			  onRequestClose={closeModal}
			  contentLabel="Actor Image"
			  className="modal"
			>
			  <button className="close-button" onClick={closeModal}></button>
			  <div className="image-container">
				<img src={actorLink} alt={name} />
			  </div>
			</Modal>
		  </div>
		)}
      </div>
      {childBubble}
    </div>
  );
};

const BubbleWebApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { firstActor, secondActor, shortestPath, hardModeMovies, isHardMode, isContinuousPlay, gamesPlayed, firstActorLink, secondActorLink} = location.state;
  const [topBubbleSelection, setTopBubbleSelection] = useState(null);
  const [bottomBubbleSelection, setBottomBubbleSelection] = useState(null);
  const [topDepth, setTopDepth] = useState(0);
  const [bottomDepth, setBottomDepth] = useState(0);
  const [showHint, setShowHint] = useState(false); // New state
  const excludedSuggestionsTop = useRef(new Map());
  const excludedSuggestionsBottom = useRef(new Map());
  const [menuOpen, setMenuOpen] = useState(false);
  const [hintSegment, setHintSegment] = useState(null);
  const [actors, setActors] = useState(null);
  const [edges, setEdges] = useState(null);
   
	const selectActor = useCallback((maxId) => {
	  const rand = Math.random() * maxId;
	  const selectedActor = actors.find(actor => actor.selection_id >= rand);
	  return selectedActor ? selectedActor : null;
	}, [actors]);

  
   useEffect(() => {
    if (!actors) {
      const fetchJSON = async () => {
        const response = await axios.get('/popular_actors.json');
        setActors(response.data);
      };
      fetchJSON();
    }
  }, [actors, isContinuousPlay]);
  
    useEffect(() => {
    const fetchJSON = async () => {
      if (!edges) {
        const response = await fetch('/edges.json');
        const data = await response.json();
        setEdges(data);
      }
    };
    fetchJSON();
  }, [edges]);
  
	function toggleMenu() {
  setMenuOpen(prevState => !prevState);
	}

	const handleHintClick = () => {
    setHintSegment(getRandomPathSegment(shortestPath));
     setShowHint(!showHint);
	};

  const updateExcludedSuggestions = (ref, depth) => {
    const newMap = new Map();
    ref.current.forEach((value, key) => {
      if (key <= depth) {
        newMap.set(key, value);
      }
    });
    ref.current = newMap;
  };

  const handleSelectTop = (item, depth) => {
    setTopBubbleSelection(item);
    setTopDepth(depth);
    updateExcludedSuggestions(excludedSuggestionsTop, depth);
  };

  const handleSelectBottom = (item, depth) => {
    setBottomBubbleSelection(item);
    setBottomDepth(depth);
    updateExcludedSuggestions(excludedSuggestionsBottom, depth);
  };

  const shareImage = useCallback(() => {
    setTimeout(() => {
      const node = document.getElementsByClassName('bubble-container')[0];

      if (!node) {
        console.error("Couldn't find the element with that id.");
        return;
      }

      html2canvas(node)
        .then((canvas) => {
          const imgData = canvas.toDataURL();
          const link = document.createElement('a');
          link.href = imgData;
          link.download = 'Movie-Connection.png';
          link.click();
        })
        .catch((error) => {
          console.error('oops, something went wrong!', error);
        });
    }, 0);  // Use 0 as the timeout to execute this as soon as the current call stack is empty
  }, []);
  
const resetGame = () => {
  const maxSelectionId = Math.max(...actors.map(actor => actor.selection_id));
  let newFirstActor = selectActor(maxSelectionId);
  let newSecondActor = selectActor(maxSelectionId);
  while (newSecondActor === newFirstActor) {
    newSecondActor = selectActor(maxSelectionId);
  }
  const newGamesPlayed = gamesPlayed+1;
  const newHardModeMovies = [
    ...hardModeMovies, 
    ...Array.from(excludedSuggestionsTop.current.values()).filter((_, index) => index % 2 === 1), 
    ...Array.from(excludedSuggestionsBottom.current.values()).filter((_, index) => index % 2 === 1)
  ];

  axios.get(`${process.env.REACT_APP_SERVER_URL}`, {
      params: {
        a: newFirstActor.primaryName,
        b: newSecondActor.primaryName,
        is_hard: isHardMode,
      },
    }).then(response => {
      navigate(location.pathname, { 
        state: {
          firstActor: newFirstActor.primaryName,
          secondActor: newSecondActor.primaryName,
          firstActorLink: newFirstActor.profile_path,
          secondActorLink: newSecondActor.profile_path,
          shortestPath: response.data,
          hardModeMovies: newHardModeMovies,
          isHardMode: isHardMode,
          isContinuousPlay: isContinuousPlay,
          gamesPlayed: newGamesPlayed,
        },
        replace: true,
      });
      // Clear the previous state
      setTopBubbleSelection(null);
      setBottomBubbleSelection(null);
      setTopDepth(0);
      setBottomDepth(0);
      excludedSuggestionsTop.current.clear();
      excludedSuggestionsBottom.current.clear();
    }).catch(error => {
      console.error('Failed to get the shortest path', error);
      // Show an error message to the user
    });
};


  function openSwalFire() {
  const pathString = String(shortestPath);
  const count = (pathString.match(/::/g) || []).length / 2;
  Swal.fire({
    title: 'You Did It!',
    html: `
      <p>You solved this match in ${(topDepth + bottomDepth) / 2} steps! <br/> The shortest path is ${count}.</p>
      <button id="tryAgainButton" class="swal2-confirm swal2-styled" style="display: inline-block; margin: 0.5em; background-color: #1E6958; border-radius: 2em;">🔁 Try Again</button>
      <button id="newGameButton" class="swal2-confirm swal2-styled" style="display: inline-block; margin: 0.5em; background-color: #F8C9FF; border-radius: 2em;">✨ Start New</button>
      <br/>
      <button id="shareButton" style="display: inline-block; margin: 0.5em; background-color: transparent; border: none;">⬆️ <span style="text-decoration: underline;">Share</span></button>
    `,
    icon: 'success',
    showConfirmButton: false, // Hide the confirm button
    showCancelButton: false,
    cancelButtonText: 'Close',
    didOpen: () => {
      const shareButton = document.getElementById('shareButton');
      shareButton.addEventListener('click', shareImage);

      const newGameButton = document.getElementById('newGameButton');
      newGameButton.addEventListener('click', () => {
        window.location.href = '/'; // navigate to the first page of your app
      });

      const tryAgainButton = document.getElementById('tryAgainButton');
      tryAgainButton.addEventListener('click', () => {
        window.location.reload(); // refreshes the browser
      });
    }
  });
}

  useEffect(() => {
    if (
      (topBubbleSelection !== null && bottomBubbleSelection !== null && topBubbleSelection === bottomBubbleSelection) ||
      topBubbleSelection === secondActor ||
      bottomBubbleSelection === firstActor
    ) {

    const excludedTop = Array.from(excludedSuggestionsTop.current.values());
    const excludedBottom = Array.from(excludedSuggestionsBottom.current.values()).reverse();
	let choices = excludedTop.concat(excludedBottom);

    // Convert choices array to a string separated by "::"
    choices = choices.join('::');

   const newUrl = `${process.env.REACT_APP_SERVER_URL}`.replace('/echo', '/save');

    axios.post(newUrl, null, {
        params: {
            choices: choices,
			is_hard: isHardMode
        }
    })
      if(isContinuousPlay&&(gamesPlayed<4)) {
        resetGame(); // Function to reset your game state
      } else {
        openSwalFire();
      }
    }
  }, [topBubbleSelection, bottomBubbleSelection, topDepth, bottomDepth, firstActor, secondActor, shortestPath, isContinuousPlay, shareImage]);

  return (
  <>
   <Helmet>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet" />
  </Helmet>
  <div className="header">
    <div className="menu">
  <img src="./images/Reelationships_hamburger.png" alt="menu" onClick={toggleMenu} />
</div>
  </div>
  {menuOpen && (
    <div className="menu-items">
        <div className="menu-item" onClick={() => window.location.href = '/'}>Start New</div>
        <div className="menu-item" onClick={() => window.location.reload()}>Try Again</div>
        <div className="menu-item" onClick={shareImage}>Share</div>
    </div>
	)}
  <div className="logo"></div>
    <div className="app-container">
      <div className="attribution-container">
        <p>Data sourced from TMDB</p>
      </div>
      <div className={`bubble-container ${isContinuousPlay ? 'bubble-container-continuous-play' : ''}`}>
	   {isContinuousPlay && <div className="score-card"> <p>Games Won: <span>{gamesPlayed}</span></p></div>}
        <div className="inner-container">
          <div className="top-bubble">
			<Bubble
			  gamesPlayed={gamesPlayed}
			  name={firstActor}
			  setSelectedItem={handleSelectTop}
			  excludedSuggestions={excludedSuggestionsTop}
			  depth={0}
			  edges={edges}
			  setDepth={setTopDepth}
			  hardModeMovies = {hardModeMovies}
			  actorLink={firstActorLink}
			/>
			</div>
			<div className="bottom-bubble">
			<Bubble
			  gamesPlayed={gamesPlayed}
			  name={secondActor}
			  setSelectedItem={handleSelectBottom}
			  excludedSuggestions={excludedSuggestionsBottom}
			  depth={0}
			  reverse
			  edges={edges}
			  setDepth={setBottomDepth}
			  hardModeMovies = {hardModeMovies}
			  actorLink={secondActorLink}
			/>
          </div>
        </div>
      </div>
      {showHint && <div>One stop in path: {hintSegment}</div>}
      <div className="button-container">
        <button onClick={handleHintClick}>Hint</button>
      </div>
	 
    </div>
	</>
  );
};

export default BubbleWebApp;
