// App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import BubbleWebApp from './BubbleWebApp';
import StartPage from './StartPage';
import ReactGA from "react-ga4";

ReactGA.initialize("G-FZT5WEBE1E");

const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send('page_view');
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      <TrackPageViews />
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/app" element={<BubbleWebApp />} />
      </Routes>
    </Router>
  );
};

export default App;
