// StartPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './StartPage.css';
import { Helmet } from 'react-helmet';

const StartPage = () => {
  const [actors, setActors] = useState([]);
  const [isHardMode, setIsHardMode] = useState(false);
  const [isContinuousPlay, setIsContinuousPlay] = useState(false);  // New state for Continuous Play
  const [hardModeMovies, setHardModeMovies] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isHardMode) {
      const fetchHardModeMovies = async () => {
        const response = await fetch('/hardmode_movies.json');
        const data = await response.json();
        setHardModeMovies(data.movies);
      };
      fetchHardModeMovies();
    } else {
      setHardModeMovies([]);
    }
  }, [isHardMode]);
  
  useEffect(() => {
    const fetchJSON = async () => {
      const response = await axios.get('/popular_actors.json');
      setActors(response.data);
    };
    fetchJSON();
  }, []);
  
	  const handleStartClick = async () => {
	  const maxSelectionId = Math.max(...actors.map(actor => actor.selection_id));
	  let firstActor = selectActor(maxSelectionId);
	  let secondActor = selectActor(maxSelectionId);
	  while (secondActor === firstActor) {
		secondActor = selectActor(maxSelectionId);
	  }

	  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}`, {
		params: {
		  a: firstActor.primaryName,
		  b: secondActor.primaryName,
		  is_hard: isHardMode,
		},
	  });

	  const shortestPath = response.data;
	  const gamesPlayed = 0;
	  navigate('/app', {
		state: {
		  firstActor: firstActor.primaryName,
		  secondActor: secondActor.primaryName,
		  firstActorLink: firstActor.profile_path,
		  secondActorLink: secondActor.profile_path,
		  shortestPath,
		  hardModeMovies,
		  isHardMode,
		  isContinuousPlay,
		  gamesPlayed,
		},
	  });
	};

  const handleHardModeChange = (event) => {
    setIsHardMode(event.target.checked);
  };

  const handleContinuousPlayChange = (event) => {  // New event handler for Continuous Play
    setIsContinuousPlay(event.target.checked);
  };

	const selectActor = (maxId) => {
	  const rand = Math.random() * maxId;
	  const selectedActor = actors.find(actor => actor.selection_id >= rand);
	  return selectedActor ? selectedActor : null;
	};

  return (
  <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet" />
    </Helmet>
    <div className="header"></div>
    <div className="logo"></div>
    <div className="start-page-container">
	 <div className="start-button-container">
        <button className="start-button" onClick={handleStartClick}>New Game</button>
      </div>
      <div className="hard-mode-container">
	   <h2 className="settings-heading">⚙️ Settings</h2>
	    <div className="options-container">
			<div className="option" id="continuous-play-option">
        <div>
		  <input
			type="checkbox"
			id="continuous-play-checkbox"
			checked={isContinuousPlay}
			onChange={handleContinuousPlayChange}
		  />
		  <label htmlFor="continuous-play-checkbox">Continuous Play</label>
		  <div className="hard-mode-message">
			 <span className="line-break-smaller"></span>
			Automatically advance to a new match after a solve but you can't reuse movies - you win when you've solved 5 in a row.
		  </div>
		</div>
		</div>
		<div className="option" id="hard-mode-option">
        <div>
          <input
            type="checkbox"
            id="hard-mode-checkbox"
            checked={isHardMode}
            onChange={handleHardModeChange}
          />
          <label htmlFor="hard-mode-checkbox">Hard Mode</label>
          <div className="hard-mode-message">
		   <span className="line-break-smaller"></span>
            Excludes the option to select movies from the MCU, as well as the Ocean's series and Love Actually.
          </div>
		  </div>
		 </div> 
        </div>
      </div>
    </div>
  </>
);
};

export default StartPage;
